<template>
  <div>
    <optionscard :deliverParentNum="5"></optionscard>
    <div class="bigpicture">
      <!-- 大图“地震知识” -->
      <img :src="imageThree" class="bigPic" width="100%" height="100%" />
    </div>
    <div class="width1200">
      <div class="bigdiv">
        <!-- 左边文章内容 -->
        <div class="leftDiv">
          <div
            class="classify-right-div"
            @click="getArticle(item.id)"
            v-for="item in earthquakeList"
            :key="item.id"
          >
            <!-- 左边的图 -->
            <div class="left-picture">
              <img :src="item.image" width="100%" height="100%" />
            </div>
            <!-- 右边的字 -->
            <div class="rightWord">
              <!-- 标题 -->
              <div class="classify-title">
                {{ item.title }}
              </div>
              <!-- 文字 -->
              <div class="rightContent" v-html="item.content"></div>
              <!-- 时间 -->
              <div class="rightTime">
                {{ item.issue_time }}
              </div>
            </div>
          </div>
        </div>
        <!-- 右边（热门知识） -->
        <div class="hot-knowledge-div1">
          <div class="hotKnowledgeTop">
            <div class="hot-knowledge1">热门知识</div>
          </div>
          <div class="hotKnowledgeDown">
            <div
              class="hot-article-div1"
              v-for="item in hotKnowledgeList"
              :key="item.id"
              @click="hotKnowledge(item.id)"
            >
              <!-- 热门知识文章标题 -->
              <div class="hot-article-title1">
                {{ item.title }}
              </div>
              <!-- 热门知识文章内容 -->
              <div class="hot-article-body1" v-html="item.content"></div>
              <!-- 热门知识文章时间 -->
              <div class="hot-article-data1">{{ item.issue_time }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination
        class="elPagination1"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="earthquake.page"
        :page-size="earthquake.paginate"
        layout="total, slot, prev, pager, next, jumper"
        :total="total"
      >
        <span class="paginationSpan" > 记录 </span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import optionscard from '@/components/optionscard.vue'
export default {
  components: {
    // 头部选项卡（首页 场馆展品 场馆活动 地震知识 参观服务
    optionscard
  },
  data () {
    return {
      // 存放地震知识数据
      earthquakeList: [],
      // 存放热门知识数据
      hotKnowledgeList: [],
      // 地震知识参数
      earthquake: {
        pid: 2,
        paginate: 6,
        page: 1
      },
      total: 0,
      // 存放广告图
      imageThree: []
    }
  },
  created () {
    this.getearthquakeList()
    this.getHotKnowledge()
    this.getAdvertisingPic()
  },
  methods: {
    // 获取地震知识文章数据
    async getearthquakeList () {
      const { res } = await this.$api.earthquakeknowledge.getearthquakeList(
        this.earthquake
      )
      console.log('res', res)
      for (const i of res.data) {
        // 标题字太多给... 只显示一行
        // if (i.title.length > 30) {
        //   i.title = i.title.slice(0, 30) + '...'
        // }
        //  删去内容里的html标签和空格 (/<img.*?><br\/?>/g, '')
        const reg = /<\/?.+?\/?>/g
        i.content = i.content.replace(reg, '')
        i.content = i.content.replace(/<\/?p>/g, '')
        i.content = i.content.replace(/&nbsp;/gi, '')
        // 字太多给... 只显示一行
        // if (i.content.length > 30) {
        //   i.content = i.content.slice(0, 30) + '...'
        // }
      }
      console.log('res.data', res.data)
      for (const i of res.data) {
        // 去除时间后面的时分秒
        if (i.issue_time.length > 10) {
          i.issue_time = i.issue_time.slice(0, 10)
        }
      }
      this.earthquakeList = res.data
      console.log('earthquakeList', this.earthquakeList)
      this.total = res.total
    },
    // 获取广告图
    async getAdvertisingPic () {
      const { res } = await this.$api.venueactivity.getAdvertisingPic(
        this.advertisingList
      )
      console.log('res123', res)
      this.imageThree = res.zhishi[0].pic
    },
    // 获取地震知识热门知识数据
    async getHotKnowledge () {
      const { res } = await this.$api.earthquakeknowledge.getHotKnowledgeList({
        pid: 2
      })
      console.log('res2', res)
      //  删去内容里的img标签
      for (const i of res) {
        // 去除时间后面的时分秒
        if (i.issue_time.length > 10) {
          i.issue_time = i.issue_time.slice(0, 10)
        }
        // 标题字太多给... 只显示一行
        // if (i.title.length > 13) {
        //   i.title = i.title.slice(0, 13) + '...'
        // }
        //  删去内容里的html标签
        const reg = /<\/?.+?\/?>/g
        i.content = i.content.replace(reg, '')
        i.content = i.content.replace(/<\/?p>/g, '')
        i.content = i.content.replace(/&nbsp;/gi, '')
        // 字太多给... 只显示一行
        // if (i.content.length > 18) {
        //   i.content = i.content.slice(0, 18) + '...'
        // }
      }
      console.log('res212', res)
      console.log('res111', res)
      this.hotKnowledgeList = res
    },
    // 点击热门知识
    hotKnowledge (id) {
      this.$router.push({ path: 'dzzsfirst/dzzssecond', query: { id } })
    },
    // 每页几条
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.earthquake.paginate = val
      this.getearthquakeList()
    },
    // 当前第几页
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.earthquake.page = val
      this.getearthquakeList()
    },
    // 跳转
    getArticle (id) {
      this.$router.push({ path: 'dzzsfirst/dzzssecond', query: { id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.width1200 {
  width: 1258px;
  margin: 0 auto;
  margin-top: 45px;
  position: relative;
}

.bigpicture {
  width: 100%;
  height: 245px;
}

.bigPic {
  object-fit: cover;
}

.leftDiv{
  float: left;
}

.hot-knowledge-div1{
  float: right;
  margin-bottom: 100px;
}

.classify-right-div {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin: 0;
  margin-bottom: 50px;
}

.classify-right-div:hover .classify-title {
  color: #2e88e4;
}

.left-picture {
  width: 220px;
  height: 135px;
}

.rightWord {
  width: 600px;
  margin-left: 27px;
}

.classify-title {
  font-size: 20px;
  color: #333333;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rightContent {
  font-size: 16px;
  color: #777777;
  margin-top: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 400px;
}

.rightTime {
  font-size: 14px;
  color: #999999;
  margin-top: 18px;
  background-color: #f7f7f7;
  text-align: center;
  margin-right: 500px;

}

.hotKnowledgeTop {
  margin-top: 20px;
  height: 66px;
  line-height: 40px;
  border-right: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
}

.hot-knowledge1-div1 {
  width: 315px;
  height: 825px;
  margin-top: 20px;
  border: solid 1px #eaeaea;
}

.hot-knowledge1 {
  width: 100%;
  height: 66px;
  font-size: 24px;
  color: #333333;
  margin-left: 15px;
  margin-bottom: 40px;
  padding-top: 13px;
}

.hotKnowledgeDown {
  border-right: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}

.hot-article-div1 {
  border-top: 1px solid #eaeaea;
  margin: 0 18px;
  margin-bottom: 30px;
  padding-top: 30px;
  width: 355px;
}

.hot-article-div1:hover .hot-article-title1 {
  color: #2e88e4;
}

.hot-article-div1:hover {
  cursor: pointer;
}

.hot-article-title1 {
  font-size: 20px;
  color: #333333;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hot-article-body1 {
  font-size: 16px;
  margin-bottom: 16px;
  color: #777777;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.hot-article-data1 {
  font-size: 14px;
  color: #999999;
  background-color: #f7f7f7;
  text-align: center;
  margin-right: 250px;

}

.elPagination1 {
  margin: 0;
  margin-bottom: 40px;
  text-align: right;
  float: right;
}

.paginationSpan {
  font-weight: normal;
  color: #606060;
}
</style>
